import { Row, Col, Button, Divider, Pagination, Spin, Empty, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined, InboxOutlined, LinkOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../utils/config";
import Create from "../components/Sponsor/Create";
import Update from "../components/Sponsor/Update";
import Delete from "../components/Sponsor/Delete";

import Search from "antd/es/input/Search";
import endpoints from "../utils/endpoints";
import CustomTable from "../components/Table";

function Sponsors() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sponsorsTiers, setSponsorsTiers] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsor.read)
      .then((res) => {
        console.log(res);
        setData(res.data[0]);
        setSponsorsTiers(res.data[1]);
        handlePrepareData(res.data[0]);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(items) {
    let auxArray = [];
    for (let i = 0; i < items.length; i++) {
      let arrayData = items[i];

      let aux = {
        key: i,
        number: i + 1,
        img: <img className="max-h-16 max-w-full" src={`${config.server_ip}media/${arrayData.img}`} />,
        name: arrayData.name,
        link: <LinkOutlined className="link-action" onClick={() => window.open(arrayData.link)} />,
        tier_name: arrayData.tier_name,
        type: arrayData.type,
        pos: arrayData.pos,
        actions: (
          <div className="flex justify-end items-center">
            <Button type="primary" className="flex justify-center items-center mr-2" onClick={() => handleOpenUpdate(arrayData)}>
              <EditOutlined />
            </Button>
            <Button danger className="flex justify-center items-center" onClick={() => handleOpenDelete(arrayData)}>
              <DeleteOutlined />
            </Button>
          </div>
        ),
        full_data: arrayData,
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} sponsorsTiers={sponsorsTiers} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} sponsorsTiers={sponsorsTiers} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Patrocinadores</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Button size="large" className="flex justify-center items-center mr-2" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                { title: "#", dataIndex: "pos", key: "pos", width: 100 },
                { title: "", dataIndex: "img", key: "img", width: 250 },
                { title: "Nome", dataIndex: "name", key: "name" },
                {
                  title: "Tier",
                  dataIndex: "tier_name",
                  key: "id_tier",
                  filters: sponsorsTiers.map((item) => ({
                    text: item.name,
                    value: item.id,
                  })),
                },
                {
                  title: "Tipo",
                  dataIndex: "type",
                  key: "type",
                  filters: [
                    {
                      text: "Científico",
                      value: "Científico",
                    },
                    {
                      text: "Patrocinador",
                      value: "Patrocinador",
                    },
                  ],
                },
                { title: "", dataIndex: "actions", key: "actions" },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Sponsors;
